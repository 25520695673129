import React from "react"
import Layout from "../../components/layout/Layout"
import "./LibroReclamaciones.css"
import Button from "../../components/button/Button"
export default () => {
  return (
    <Layout type="HMNF" title="Libro de Reclamaciones">
      <div className="book-content">
        <h1>Libro de Reclamaciones</h1>
        <header className="book-header">
          <div className="book-id">
            <p>Fecha: 16/09/2020</p>
            <p>Nro de Reclamo: PE-KMH-00000203-2020</p>
          </div>
          <div className="book-ruc">
            <p>Taller de Confecciones San Luis S.A.</p>
            <p>RUC: 20102266359</p>
            <p>Taller de Confecciones San Luis S.A.</p>
          </div>
        </header>
        <main className="book-main">
          <div className="mandatory">* Campos obligatorios</div>
          <section>
            <h2>1. Identificación del Consumidor Reclamante</h2>
            <div className="controls">
              <div className="control">
                <label htmlFor="name">Tipo Texto *</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="control">
                <label htmlFor="name">Tipo Combobox *</label>
                <select name="location" id="location">
                  <option value="o1">opción 1</option>
                  <option value="o2">opción 2</option>
                  <option value="o3">opción 3</option>
                </select>
              </div>
              <div className="control">
                <label htmlFor="name">Tipo Combobox *</label>
                <select name="location" id="location">
                  <option value="o1">opción 1</option>
                  <option value="o2">opción 2</option>
                  <option value="o3">opción 3</option>
                </select>
              </div>
              <div className="control">
                <label htmlFor="name">Tipo Texto *</label>
                <input type="text" name="name" id="name" />
              </div>
            </div>
          </section>
          <section>
            <h2>2. Identificación del Bien Contratado</h2>
            <div className="controls">
              <div className="control">
                <label htmlFor="name">Tipo Radio Buttons</label>
                <input type="radio" id="male" name="gender" value="male" />
                <label htmlFor="male">Opción 1</label>
                <input type="radio" id="female" name="gender" value="female" />
                <label htmlFor="female">Opción 2</label>
              </div>
              <div className="control">
                <label htmlFor="name">Tipo Text Area *</label>
                <textarea id="w3review" name="w3review" rows="4"></textarea>
              </div>
              <div className="control">
                <label htmlFor="name">Tipo Combobox *</label>
                <select name="location" id="location">
                  <option value="o1">opción 1</option>
                  <option value="o2">opción 2</option>
                  <option value="o3">opción 3</option>
                </select>
              </div>
              <div className="control">
                <label htmlFor="name">Tipo Texto *</label>
                <input type="text" name="name" id="name" />
              </div>
            </div>
          </section>
          <div className="book-footer">
            <Button>Enviar</Button>
          </div>
        </main>
      </div>
    </Layout>
  )
}
